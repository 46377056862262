<template>
  <el-dialog
      title="转办"
      :visible="isShowTransfer"
      width='560px'
      :append-to-body="true"
      :destroy-on-close="true"
      :close-on-click-modal='false'
      @close="onClose">
      <el-form class="global-add-form" ref="form" :model="formData" :rules="rules" label-width="100px" label-position="right">
          <el-form-item label="任务执行人" prop="executeUserId">
              <el-select placeholder="请选择任务执行人" v-model="formData.executeUserId" filterable clearable>
                  <el-option v-for="(item, index) in userList" :label="item.userName" :value="item.userId" :key="index"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="onSave" :disabled="disabled">保存</el-button>
              <el-button type="info" @click="onClose">取消</el-button>
          </el-form-item>
      </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      disabled: false,
      userList: [],
      formData: {
        executeUserId: ''
      },
      rules: {
        executeUserId: [
          { required: true, message: '请选择转办人', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created () {
    // ...
  },
  components: {},
  props: {
    isShowTransfer: {
      type: Boolean,
      default: false
    },
    taskId: String,
    patrolUnit: Number,
    executeUserId: String,
    safeManageId: [String, Number],
    projectId: String
  },
  computed: {},
  watch: {
    isShowTransfer (val) {
      if (val) {
        this.loadUserList()
      }
    }
  },
  methods: {
    loadUserList () {
      this.$axios.get(this.$apis.patrol.selectUserList, {
        userType: '1',
        projectId: this.projectId,
        safeManageId: this.safeManageId,
        userSource: this.patrolUnit === 1 ? '1' : '0'
      }).then(res => {
        const list = res || []
        if (list.length) {
          this.userList = list.filter(item => item.userId !== this.executeUserId)
        } else {
          this.userList = []
        }
      })
    },
    onSave () {
      this.$refs.form.validate().then(() => {
        this.disabled = true
        this.$axios.post(this.$apis.patrol.transferTask, {
          taskId: this.taskId,
          executeUserId: this.formData.executeUserId
        }).then(res => {
          this.onClose()
          this.formData.executeUserId = ''
          this.$emit('loadTask')
        }).finally(() => {
          this.disabled = false
        })
      })
    },
    onClose () {
      this.$emit('update:isShowTransfer', false)
    }
  }
}
</script>

<style scoped>

</style>
